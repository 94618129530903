var AES = require('crypto-js/aes');
var Base64 = require('crypto-js/enc-base64');
var Utf8 = require('crypto-js/enc-utf8');
var Hex = require('crypto-js/enc-hex');

function EncryptFactory() { }

/**
 * Rsa encrypted string
 * @param {rsa publicKey} publicKey
 * @param {encrtyped source value} value
 */
EncryptFactory.prototype.encryptByRsa = function (publicKey, value) {
  var encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  return encrypt.encrypt(value);
}

/**
 * Aes encrypted string
 * @param {aes publicKey} publicKey
 * @param {aes iv} iv
 * @param {encrtyped source value} value
 */
EncryptFactory.prototype.encryptByAes = function (publicKey, iv, value) {
  publicKey = Utf8.parse(publicKey||sessionStorage.getItem("PublicKey"));
  iv = Utf8.parse(iv||sessionStorage.getItem("PublicKey"));
  value = Utf8.parse(value);
  var aesEncryptedValue = AES.encrypt(value, publicKey, { iv: iv }).ciphertext.toString();
  var aesEncryptedHexValue = Hex.parse(aesEncryptedValue);
  var aesEncryptedBase64Value = Base64.stringify(aesEncryptedHexValue)
  return aesEncryptedBase64Value;
}


/**
 * Aes decrypted string
 * @param {aes publicKey} publicKey
 * @param {aes iv} iv
 * @param {aes encrypted string} encryptedValue
 */
EncryptFactory.prototype.decryptByAes = function (publicKey, iv, encryptedValue) {
  publicKey = Utf8.parse(publicKey||sessionStorage.getItem("PublicKey"));
  iv = Utf8.parse(iv||sessionStorage.getItem("PublicKey"));
  encryptedValue = (encryptedValue + '').replace(/\n*$/g, '').replace(/\n/g, '');
  var aesDecryptedValue = AES.decrypt(encryptedValue, publicKey, { iv: iv }).toString(Utf8);
  return aesDecryptedValue;
}


/**
 * @description encrypted pin with rsa and aes
 */
EncryptFactory.prototype.encryptedPINCode = function (val, rules) {
  if (!val || val === null || val === undefined) return '';
  var publicKey = rules.RSAPublicKey;
  var aesKey = rules.AESKey
  var offset = rules.OFFSET
  var salt = rules.SALT;
  // rsa encryption
  var rsaEncryptedValue = this.encryptByRsa(publicKey, val);
  // rsa ciphertext stitching salt
  rsaEncryptedValue += salt;
  // aes encryption, set offset
  var aesEncryptedValue = this.encryptByAes(aesKey, offset, rsaEncryptedValue);
  return aesEncryptedValue;
}
module.exports = EncryptFactory;

// default language is english
var DEAULT_LANGUAGE = 'en-US';

// load the properties
function loadProperties(language) {
  language = language || DEAULT_LANGUAGE;
  jQuery.i18n.properties({
    //Load resource file corresponding to browser language
    name: "strings", //Resource file name
    path: "./i18n/", //Resource file path
    mode: "map", //Use the values in the resource file with Map
    language: language,
    callback: function () {
      var eles = $(".i18n");
      eles.each(function () {
        $(this).html($.i18n.prop($(this).attr('name')));
      })
    }
  });
}

loadProperties();

/**
 * Get query string
 * @param {params's key} key 
 * @param {source string} paramString 
 */
function getQueryString(key, paramString) {
  var paramStr = paramString || window.location.search;
  var regExp = new RegExp('(^|\\?|&)' + key + '=([^&]*)(\\s|&|$)');
  return regExp.test(paramStr) ? RegExp.$2 : '';
}

window.getQueryString = getQueryString;